import React from "react";
import { createFromIconfontCN } from "@ant-design/icons";
import "./index.less";
// antd 字体图标
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1557419_kughl0mwhpa.js"
});

interface MainProps {
  [key: string]: any;
  type: string;
}
const Icon: React.FC<MainProps> = (props) => <IconFont {...props} />;
export default Icon;