const login = {
	PASS_NUMBER_LOGIN: '/elebuys/right/login/login',
	PHONE_CODE_LOGIN_FIRST: '/elebuys/right/login/login/telephone/step1',
	PHONE_CODE_LOGIN_SECOND: '/elebuys/right/login/login/telephone/step2',
	GET_YZCODE: '/elebuys/sms/v2/sms-code', //获取验证码
	GET_LOGIN_INFO: '/psm/login-page/query', //获取登录页信息
	GET_COMNAME: '/member/sms-signature/default/get', //获取企业短信签名
	safe_check: '/elebuys/right/login/safe-check', //登录安全校验
	getPicture: '/basic/captcha/get',
	reqCheck: '/basic/captcha/check'
};

export default login;
