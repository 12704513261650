import axios, { AxiosResponse, AxiosRequestConfig, AxiosError, Method } from 'axios';
import { notification } from '@fle-ui/next';
import { MAIN_HOST } from 'config';
interface RequestConfig extends AxiosRequestConfig {
	notice?: boolean;
}

interface PendingType {
	url?: string;
	method?: Method;
	params: any;
	data: any;
	cancel: Function;
}

// 登录失效
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const service = axios.create({
	baseURL: MAIN_HOST,
	timeout: 10000
});

const pending: Array<PendingType> = [];

// 移除重复请求
const removePending = (config: RequestConfig) => {
	for (const key in pending) {
		const item: number = +key;
		const list: PendingType = pending[key];
		// 当前请求在数组中存在时执行函数体
		if (
			list.url === config.url &&
			list.method === config.method &&
			JSON.stringify(list.params) === JSON.stringify(config.params) &&
			JSON.stringify(list.data) === JSON.stringify(config.data)
		) {
			// 执行取消操作
			list.cancel('操作太频繁，请稍后再试');
			// 从数组中移除记录
			pending.splice(item, 1);
		}
	}
};

service.interceptors.request.use(
	(config: RequestConfig) => {
		removePending(config);
		// config.headers["authority_token"] = Cookies.get("token") || "";
		config.url = `${config.url}?__date=${new Date().getTime()}`;

		return {
			...config,
			cancelToken: source.token
		};
	},
	(err: AxiosError) => {
		notification.error({
			message: '发送请求失败',
			description: err
		});
		return false;
	}
);

service.interceptors.response.use(
	(res: AxiosResponse) => {
		console.log('错误拦截', res);
		const { status, data, statusText, config } = res;

		removePending(config);

		return new Promise<any>((resolve, reject) => {
			if (status < 200 || status > 300) {
				notification.error({
					message: `请求错误: ${status}`,
					description: statusText
				});
				reject({ ...data, data: null });
			} else if (!data.success) {
				// 异地登录拦截
				if (data?.code == '60000302') {
					reject({ ...data });
					return;
				}

				notification.error({
					message: '提示',
					description: data.message
				});

				reject({ ...data, data: null });
			} else {
				resolve(data);
			}
		});
	},
	(err: AxiosError) => {
		return notification.error({
			message: '提示',
			description: String(err)
		});
	}
);

function get<T = any>(url: string, config?: RequestConfig): Promise<T> {
	return service.get(url, config);
}

function post<T = any>(url: string, data?: any, config?: RequestConfig): Promise<T> {
	return service.post(url, data, config);
}

//转换类型
declare module 'axios' {
	interface AxiosInstance {
		(config: RequestConfig): Promise<any>;
	}
}

const HttpApi = {
	get,
	post
};

export default HttpApi;
