import React from 'react';
import { NavTypes } from '../../types/components/nav.interface';
import './index.less';
import { useHistory } from "react-router-dom";
import { IS_MAIL, IS_SELLER } from "../../config/url";

const TypeTextMap = new Map([
  [1, "登录"],
  [2, "选择账号"],
  [3, "找回密码"],
])
const Nav: React.FC<NavTypes> = (props: NavTypes) => {
  let history = useHistory();
  const {
    data: {
      logoImg
    },
    type
  } = props;

  return (
    <div className='nav_wrap'>
      <div className="nav_wrap_title">
        <div className='nav_wrap_title_wrap fx-flexbox fx-flexbox-item'>
          <div className='nav_wrap_title_content' onClick={() => history.push("/index")}>
            {(IS_MAIL || IS_SELLER) ? <img src="https://oss.elebuys.com/tmpdir/202108051526360006361028.png" alt="" /> : <img src={logoImg} alt="" />}
          </div>
          <div className="nav_wrap_title_line"></div>
          <div className='nav_wrap_title_type'>{TypeTextMap.get(type)}</div>
        </div>

        {type !== 1 && (
          <div className='fx-flexbox right-btn'>
            <div className="backLogin" onClick={() => history.push("/index")}>
              登录
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
Nav.defaultProps = {}
export default Nav;
