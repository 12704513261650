import qs, { ParsedQs } from 'qs'

/**
 * 获取路径query参数
 */
export const getQuery = (): ParsedQs => qs.parse(window.location.search.substring(1));

// 密码安全校验
export const validatePassword = (password: any) => {
  if (!password) {
    return false
  }

  // 密码安全校验
  const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,16}$/;
  return regex.test(password);
}

/**
 * 获取主域名
 */
export const getMainHost = () => {
  let key = `mh_${Math.random()}`;
  let keyR = new RegExp(`(^|;)\\s*${key}=12345`);
  let expiredTime = new Date(0);
  let domain = document.domain;
  let domainList = domain.split('.');

  let urlItems = [];
  // 主域名一定会有两部分组成
  urlItems.unshift(domainList.pop());
  // 慢慢从后往前测试
  while (domainList.length) {
    urlItems.unshift(domainList.pop());
    let mainHost = urlItems.join('.');
    let cookie = `${key}=${12345};domain=.${mainHost}`;

    document.cookie = cookie;

    //如果cookie存在，则说明域名合法
    if (keyR.test(document.cookie)) {
      document.cookie = `${cookie};expires=${expiredTime}`;
      return mainHost;
    }
  }
}