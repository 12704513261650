import { makeAutoObservable, configure } from "mobx";
import { CommonTypes } from "types/store/common.interface";

configure({ enforceActions: "observed" });

class CommonStore implements CommonTypes {
  [key: string]: any;
  constructor() {
    makeAutoObservable(this);
  }
}

export default new CommonStore();
