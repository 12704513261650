import { makeAutoObservable, configure, action } from "mobx";
import { FindpwdTypes } from "../types/store/findpwd.interface";
import { findpwd } from "../api";
import { httpClient } from "../tools";

configure({ enforceActions: "observed" });

class findpwdStore implements FindpwdTypes {
  [key: string]: any;
  constructor() {
    makeAutoObservable(this);
  }

  // 找回密码第一步
  @action async rePwdFirst(params: any) {
    const data = await httpClient.post(findpwd.PWD_FIRST_STEP, { ...params });
    // console.log(data);
    return data
  }

  // 找回密码第一步
  @action async rePwdSecond(params: any) {
    const data = await httpClient.post(findpwd.PWD_SECOND_STEP, { ...params });
    // console.log(data);
    return data
  }


  // 发送验证码
  @action async rePwdYzCode(params: any) {

    const data = await httpClient.post(findpwd.PWD_GET_YZCODE, { ...params });
    // console.log(data);
    return data
  }


}

export default new findpwdStore();
