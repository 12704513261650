type envType = string | undefined
const ENV: envType = process.env.REACT_APP_API_ENV
const API_HOST: Map<envType, string> = new Map([
  ['dev', 'https://dev-gateway.fxqifu.net/pangu'],
  ['qa', 'https://qa-gateway.fxqifu.net/pangu'],
  ['prod', 'https://gateway.fxqifu.net/pangu'],
])
const MAIN_HOST = API_HOST.get(ENV)

const PELITEN_HOST: Map<envType, string> = new Map([
  ['dev', 'http://dev-home.peliten.com'],
  ['qa', 'https://qa-home.peliten.com'],
  ['prod', 'https://home.peliten.com'],
])
const PELITEN_MAIN_HOST = PELITEN_HOST.get(ENV)

const PELITEN_SUPPLIER_HOST: Map<envType, string> = new Map([
  ['dev', 'http://dev-s.peliten.com'],
  ['qa', 'https://qa-s.peliten.com'],
  ['prod', 'https://s.peliten.com'],
])
const PELITEN_SUPPLIER_MAIN_HOST = PELITEN_SUPPLIER_HOST.get(ENV)

const ELEBUYS_MAIL_HOST: Map<envType, string> = new Map([
  ['dev', 'http://dev-www.elebuys.com/index'],
  ['qa', 'http://qa-www.elebuys.com/index'],
  ['prod', 'https://mall.fxqifu.com/index'],
])
const ELEBUYS_MAIL_MAIN_HOST = ELEBUYS_MAIL_HOST.get(ENV)

const ELEBUYS_SELLER_HOST: Map<envType, string> = new Map([
  ['dev', 'http://dev-seller.elebuys.com'],
  ['qa', 'http://qa-seller.elebuys.com'],
  ['prod', 'https://seller.elebuys.com'],
])
const ELEBUYS_SELLER_MAIN_HOST = ELEBUYS_SELLER_HOST.get(ENV)

let SITE_URL = window.location.hostname
// let SITE_URL = "dev-fuli.peliten.com";
// SITE_URL = 'qa-abctest.fxliye.com'
// SITE_URL = 'dev-www.peliten.com'
// SITE_URL = 'qa-design.fxqifu.com'

const IS_LOCALHOST = /localhost/.test(SITE_URL)
const IS_MAIL =
  ENV === 'prod'
    ? /mall\.fxqifu\.com/.test(SITE_URL)
    : /www\.elebuys\.com/.test(SITE_URL)
const IS_SUPPLIER =
  /supplier\./.test(SITE_URL) ||
  /\.s\./.test(SITE_URL)
// const IS_SUPPLIER_TRUE = /supplier.peliten.com/.test(SITE_URL);
const IS_SELLER = /seller\.elebuys\.com/.test(SITE_URL)
const IS_PELITEN_TRUE = /www.peliten.com/.test(SITE_URL)
const IS_DESIGN = /design\.fxqifu\.com/.test(SITE_URL)
const IS_YUNSHANG = /yunshang\.fxqifu\.com/.test(SITE_URL) || /yunshang\.fxqf\.com/.test(SITE_URL)
const IS_YUNQI = /yunqi\.fxqifu\.com/.test(SITE_URL) || /yunqi\.fxqf\.com/.test(SITE_URL)
const IS_FULI = /fuli\.peliten\.com/.test(SITE_URL);

export const IS_ZX = /zx\.fxqifu\.com/.test(SITE_URL)
const IS_PELITEN =
  /peliten.com/.test(SITE_URL) &&
  !IS_SUPPLIER &&
  !IS_PELITEN_TRUE

// platform
const PLATFORM =
  IS_MAIL ||
  IS_SELLER ||
  IS_PELITEN_TRUE ||
  IS_SUPPLIER ||
  IS_LOCALHOST ||
  IS_DESIGN ||
  IS_YUNSHANG ||
  IS_YUNQI ||
  IS_FULI
    ? 3
    : 2

let PLATFORM_MAIN_HOST: any = ''

if (IS_MAIL) {
  PLATFORM_MAIN_HOST = ELEBUYS_MAIL_MAIN_HOST
} else if (IS_SELLER) {
  PLATFORM_MAIN_HOST = ELEBUYS_SELLER_MAIN_HOST
} else {
  // SITE_URL = 'qa-abctest.fxliye.com';
  PLATFORM_MAIN_HOST = PELITEN_MAIN_HOST
}

if (IS_LOCALHOST) {
  document.title = '百礼腾会员登录'
} else if (IS_SUPPLIER) {
  document.title = '百礼腾供应商登录'
} else if (IS_MAIL) {
  document.title = '选品商城会员登录'
} else if (IS_SELLER) {
  document.title = '卖家端登录'
} else {
  document.title = '百礼腾会员登录'
}
export {
  IS_FULI,
  IS_LOCALHOST,
  IS_SUPPLIER,
  IS_MAIL,
  IS_SELLER,
  MAIN_HOST,
  PELITEN_MAIN_HOST,
  PELITEN_SUPPLIER_MAIN_HOST,
  PLATFORM_MAIN_HOST,
  SITE_URL,
  ENV,
  IS_PELITEN,
  // IS_SUPPLIER_TRUE,
  IS_PELITEN_TRUE,
  PLATFORM,
}
