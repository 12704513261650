import { makeAutoObservable, configure, action } from "mobx";
import { LoginTypes } from "../types/store/login.interface";
import { login } from "../api";
import { httpClient } from "../tools";
import { IS_LOCALHOST, IS_MAIL, IS_SELLER, IS_SUPPLIER, IS_FULI } from "config/url";

configure({ enforceActions: "observed" });

class loginStore implements LoginTypes {
  [key: string]: any;
  constructor() {
    makeAutoObservable(this);
  }

  @action async passNumberLogin(params: any) {

    const data = await httpClient.post(login.PASS_NUMBER_LOGIN, { ...params });
    // console.log(data);
    return data
  }

  @action async loginSafeCheck(params: any) {

    const data = await httpClient.post(login.safe_check, { ...params });
    // console.log(data);
    return data
  }

  @action async getPicture(params: any) {
    const data = await httpClient.post(login.getPicture, { ...params });
    // console.log(data);
    return data
  }

  @action async reqCheck(params: any) {
    const data = await httpClient.post(login.reqCheck, { ...params });
    // console.log(data);
    return data
  }

  /**
   * 获取手机登录账号信息
   */
  phoneLoginInfo: any = {};
  @action getPhoneLoginInfo = async (params: any) => {

    const data = await httpClient.post(login.PHONE_CODE_LOGIN_FIRST, { ...params });
    // console.log(data);
    this.phoneLoginInfo = data;
    // console.log(this.phoneLoginInfo);
    return data
  }

  @action async getPhoneListLogin(params: any) {

    const data = await httpClient.post(login.PHONE_CODE_LOGIN_SECOND, { ...params });
    // console.log(data);
    return data
  }

  @action async getYzCode(params: any) {
    const data = await httpClient.post(login.GET_YZCODE, { ...params });
    // console.log(data);
    return data
  }

  @action async getComName(params: any) {
    const data = await httpClient.get(login.GET_COMNAME, { params });
    // console.log(data);
    return data
  }

  public comInfo:any = {
    type: 1, // 1: 登录页面  2: 选择账号页面
    slogan: "礼业500强\n专属数智化升级平台",
    welcomeWord: "欢迎使用百礼腾数智平台",
    logoImg: '//oss.elebuys.com/resource/fle-ui/202109281718480007946726.png',
    propagandaMap: '//oss.elebuys.com/tmpdir/202109301800350008146781.png',
  }

  // 是否已获取登录信息
  hasGetLoginInfo: boolean = false

  getLoginInfo = async (params: any) => {
    const res = await httpClient.post(login.GET_LOGIN_INFO, { ...params });

    this.hasGetLoginInfo = true

    if (!!res?.success && !!res?.data) {

      this.comInfo = {
        ...(res.data || {}),
        slogan: res.data?.slogan?.replace("\\n", "\n") || "",
        welcomeWords: res.data?.welcomeWords || (IS_SUPPLIER ? "欢迎使用百礼腾供应商平台" : "欢迎使用百礼腾数智平台"),
        logoImg: res.data?.portalLogo || '//oss.elebuys.com/resource/fle-ui/202109281718480007946726.png',
        propagandaMap: res.data?.propagandaMap || '//oss.elebuys.com/tmpdir/202109301800350008146781.png',
        showType: 1,
      };

      if (IS_LOCALHOST) {
        document.title = res.data?.portalName || '百礼腾会员登录';
      } else if (IS_SUPPLIER) {
        document.title = res.data?.portalName || '百礼腾供应商登录';
      } else if (IS_MAIL) {
        document.title = res.data?.portalName || '选品商城会员登录';
      } else if (IS_SELLER) {
        document.title = res.data?.portalName || '飞象平台供应商登录';
      } else if (IS_FULI) {
        document.title = res.data?.portalName || '数字福利企业端登录';
      } else {
        document.title = res.data?.portalName || '百礼腾会员登录';
      }
    }
  }

  /**
   * 更新state
   */
  updateState = (params: LoginTypes) => {
    // console.log(this.phoneLoginInfo);

    for (let [key, value] of Object.entries(params)) {

      this[key] = value;
      console.log(this.phoneLoginInfo);
    }
    // console.log(this.phoneLoginInfo);
  };
}

export default new loginStore();
