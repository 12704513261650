import React from 'react';
import './index.less';
import { loginStore } from 'stores/index';

const Footer: React.FC<any> = (props) => {
  const { color } = props;
  const openUrl = (url: string) => {
    window.open(url);
  }
  return (
    <div className='login_wrap_footer'>
      <div className="footer-record-wrap" style={{ color }}>
        <div className="displayCenter">
          {loginStore.comInfo.telecomBusinessPermitNo && <span>电信与信息服务业务经营许可证：{loginStore.comInfo.telecomBusinessPermitNo}</span>}
          {loginStore.comInfo.telecomBusinessPermitNo && (loginStore.comInfo.websiteIcpRecord || loginStore.comInfo.networkSecurityRecord) && <span style={{ margin: '0 10px', color: color }}>|</span>}
          {loginStore.comInfo.websiteIcpRecord && <span style={{ cursor: 'pointer' }} onClick={() => { openUrl('https://beian.miit.gov.cn/') }}> {loginStore.comInfo.websiteIcpRecord}</span>}
          {loginStore.comInfo.websiteIcpRecord && loginStore.comInfo.networkSecurityRecord && <span style={{ margin: '0 10px' }}>|</span>}
          {loginStore.comInfo.networkSecurityRecord && <span style={{ cursor: 'pointer' }} onClick={() => { openUrl(`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${loginStore.comInfo.networkSecurityRecord.split('_')[1]}`) }}><img src="https://oss.elebuys.com/tmpdir/202109091938170007179445.png" alt="" /> {loginStore.comInfo.networkSecurityRecord.split('_')[0]}公网安备{loginStore.comInfo.networkSecurityRecord.split('_')[1]}</span>}
        </div>
        {loginStore.comInfo.websiteCopyright && <div>©{new Date().getFullYear()} {loginStore.comInfo.websiteCopyright} All rights reserved</div>}
      </div>
    </div >
  )
}
Footer.defaultProps = {}
export default Footer;
