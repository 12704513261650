import React, {useLayoutEffect} from "react";
import { BrowserRouter } from 'react-router-dom'
import Loadable from "react-loadable";
import Cookies from "js-cookie";
import { getMainHost } from "./tools/helpers";

import "./App.less";
// components
import { Loading } from "components";

const App: React.FC = () => {
  useLayoutEffect(() => {
    const MainHost = getMainHost();
    !!Cookies.get("token", {domain: MainHost}) && Cookies.remove("token", {domain: MainHost});
    !!Cookies.get("token", {domain: MainHost}) && Cookies.remove("token", { domain: MainHost});
    !!Cookies.get("userInfo", {domain: MainHost}) && Cookies.remove("userInfo", {domain: MainHost});
    !!Cookies.get("mall_token", {domain: MainHost}) && Cookies.remove("mall_token", {domain: MainHost});
    !!Cookies.get("seller_token", {domain: MainHost}) && Cookies.remove("seller_token", {domain: MainHost});
    !!Cookies.get("supplier_token", {domain: MainHost}) && Cookies.remove("supplier_token", {domain: MainHost});
  }, [])

  return (
    <div className="App">
      <BrowserRouter basename="/login/">
        <LoadBasic />
      </BrowserRouter>
    </div>
  );
}

const LoadBasic = Loadable({
  loader: () => import("./layout/Basic"),
  loading: Loading,
});

export default App;
